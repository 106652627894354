import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  List,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  PlatformTableRow,
  Section,
  SectionSubhead,
} from 'docComponents';
import { ActionList } from 'hudl-uniform-ui-components';
import actionListPreviewData from '../../../data/previews/actionlist.yml';
import pageHeroData from '../../../data/pages/components.yml';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedType: 'label',
    };
  }

  handleStyleChange = () => {
    this.setState({
      selectedType: this.state.selectedType === 'icon' ? 'label' : 'icon',
    });
  };

  render() {
    const actions =
      this.state.selectedType === 'icon'
        ? [
            {
              label: 'Add to Playlist',
              icon: 'playlistadd',
              onClick: () => {},
            },
            {
              label: 'Share',
              icon: 'share',
              onClick: () => {},
            },
            {
              label: 'Edit',
              icon: 'edit',
              onClick: () => {},
            },
            {
              label: 'Delete',
              icon: 'delete',
              isDestructive: true,
              onClick: () => {},
            },
          ]
        : [
            {
              label: 'Add to Playlist',
              onClick: () => {
                console.log('Added to Playlist');
              },
            },
            {
              label: 'Share',
              onClick: () => {
                console.log('Shared');
              },
            },
            {
              label: 'Edit',
              onClick: () => {
                console.log('Edited');
              },
            },
            {
              label: 'Delete',
              isDestructive: true,
              onClick: () => {
                console.log('Deleted');
              },
            },
          ];

    return (
      <PageWithSubNav
        pageType="design"
        title="Action List"
        designCode
        subnav="components">
        <PageHero heroData={pageHeroData} tierTwo="Action List" />

        <PageNavigation>
          <PageNavigationLink>Type</PageNavigationLink>
          <PageNavigationLink>Style</PageNavigationLink>
          <PageNavigationLink>Mobile</PageNavigationLink>
          <PageNavigationLink>Usage</PageNavigationLink>
          <PageNavigationLink>Microcopy</PageNavigationLink>
          <PageNavigationLink>Platform</PageNavigationLink>
        </PageNavigation>

        <Section title="Type">
          <Paragraph>
            The action list type is determined both by the actions listed and
            the list’s relationship to nearby buttons.
          </Paragraph>
          <ComponentPreview
            name="ActionListType"
            previewData={actionListPreviewData.types}>
            <ActionList
              type="primary"
              actions={[
                {
                  label: 'Share',
                  onClick: () => {},
                },
                {
                  label: 'Edit',
                  onClick: () => {},
                },
                {
                  label: 'Delete',
                  isDestructive: true,
                  onClick: () => {},
                },
              ]}
            />
          </ComponentPreview>
        </Section>

        <Section title="Style">
          <Paragraph>
            The style depends on the interface, not just in terms of available
            space, but also how nearby actions appear.
          </Paragraph>
          <ComponentPreview
            name="ActionListStyle"
            previewData={actionListPreviewData.styles}
            onChange={this.handleStyleChange}>
            <ActionList type="primary" actions={actions} />
          </ComponentPreview>
        </Section>
        <Section title="Mobile">
          <Paragraph>
            Action lists are replaced by{' '}
            <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/views/action-sheets/">
              action sheets
            </Link>{' '}
            (Apple) and{' '}
            <Link href="https://material.io/components/sheets-bottom/">
              sheets
            </Link>{' '}
            (Android). They should be used in all cases. Include potentially
            destructive, and cancel actions, if necessary.
          </Paragraph>
        </Section>
        <Section title="Usage">
          <SectionSubhead>Actions</SectionSubhead>
          <List>
            <li>
              Actions listed should apply to all selected items. Once a
              selection is made, the user cannot make exceptions as to which
              items are affected.
            </li>
            <li>
              There isn’t a limit to how many actions may be provided, but there
              is a minimum of two—including any destructive actions.
              <DontDo gridLayout="2">
                <DontDoItem
                  type="dont"
                  text="list a single action when one button will do."
                  img="actionlist-itemnumber-dont"
                />
                <DontDoItem
                  type="do"
                  text="list more than one action that applies to all selected items."
                  img="actionlist-itemnumber-do"
                />
              </DontDo>
            </li>
            <li>
              Destructive actions should always be placed last in the list.
              Users can adapt to seeing items like delete near the end.
              <DontDo gridLayout="2">
                <DontDoItem
                  type="dont"
                  text="list actions in an illogical order."
                  img="actionlist-itemorder-dont"
                />
                <DontDoItem
                  type="do"
                  text="keep destructive actions anchored to the bottom."
                  img="actionlist-itemorder-do"
                />
              </DontDo>
            </li>
          </List>
          <SectionSubhead>Placement</SectionSubhead>
          <Paragraph>
            An action list should appear near the right side of an interface,
            especially when used inside of an{' '}
            <Link href="/components/action-bar" isDesignCodeLink>
              action bar
            </Link>
            .
          </Paragraph>
          <SectionSubhead>Type</SectionSubhead>
          <Paragraph>
            Because an action bar provides primary actions for the items
            selected,{' '}
            <strong>
              an action list used within any action bar should always be
              primary.
            </strong>
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="use a secondary or subtle action list in an action bar."
              img="actionlist-actionbar-dont"
            />
            <DontDoItem
              type="do"
              text="include additional primary actions."
              img="actionlist-actionbar-do"
            />
          </DontDo>
        </Section>

        <Section title="Microcopy">
          <SectionSubhead>Actions</SectionSubhead>
          <Paragraph>
            <strong>Actions should always be verbs.</strong> If you can’t stick
            to a single word, max out with a three-word phrase.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="use a complete sentence for each action."
              img="actionlist-action-dont"
            />
            <DontDoItem
              type="do"
              text="give them a single directive for all selected items."
              img="actionlist-action-do"
            />
          </DontDo>

          <SectionSubhead>Case &amp; Punctuation</SectionSubhead>
          <Paragraph>
            All item labels should follow our{' '}
            <Link href="/words/content-elements/labels-non-label-text">
              UI label guidelines
            </Link>
            . Use title case with no punctuation.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="use sentence case for an action."
              img="actionlist-sentence-dont"
            />
            <DontDoItem
              type="do"
              text="title case the entire string."
              img="actionlist-sentence-do"
            />
          </DontDo>
        </Section>

        <Section title="Platform">
          <PlatformTable>
            <PlatformTableRow platform="web" />
            <PlatformTableRow platform="apple" />
            <PlatformTableRow platform="android" />
          </PlatformTable>
        </Section>
      </PageWithSubNav>
    );
  }
}
